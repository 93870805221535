import TridentImage from "assets/buttons/trident.png";
import { Link, animateScroll as scroll } from "react-scroll";

const Trident = ({ style, text, to }) => {
    return (<>
        <Link
            className="hidden md:block z-50"
            activeClass="active"
            to={to}
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
        >

            <div className='text-lg cursor-pointer tracking-wider text-white font-extrabold'>
                <img src={TridentImage} className="animate-bounce w-24 mx-auto" />
                <div className={`${style}`}>{text}</div>

            </div>
        </Link>
    </>);
}
export default Trident