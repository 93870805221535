import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Navbar from "components/Navbar/index";
import BlueWhale from "assets/whales/Block 1/Blue whale.png";
import KillerWhale from "assets/whales/Block 1/Killer whale.png";
import NarWhale from "assets/whales/Block 1/Narwhale.png";
import RightWhale from "assets/whales/Block 1/Right Whale.png";
import SpermWhale from "assets/whales/Block 1/Sperm Whale.png";
import WhaleShark from "assets/whales/Block 1/Whale Shark.png";
import MamaTurtle from "assets/Block 6/mama-turtle.png";
import BabyTurtle from "assets/Block 6/baby-turtle.png";
import Nemo1 from "assets/Block 6/nemo 1.png";
import Nemo2 from "assets/Block 6/nemo 2.png";
import Nemo3 from "assets/Block 6/nemo 3.png";
import "./index.css";
import Trident from "components/Buttons/Trident";
import Button1 from "assets/buttons/button1.png";
import Button2 from "assets/buttons/button2.png";
import Button3 from "assets/buttons/button3.png";
import Team from "assets/team.png";
import Block9Image from "assets/Block 9/block 9.png";
import WhaleLoveImage from "assets/whaleLove.png";
import CloudLImage from "assets/buttons/cloudL.png";
import CloudRImage from "assets/buttons/cloudR.png";
import Fighting1 from "assets/Block 5/fighting1.png";
import Fighting2 from "assets/Block 5/fighting2.png";
import Fighting3 from "assets/Block 5/fighting3.png";
import ArbiterIcon from "assets/aqua_icon.png";
import Mountain from "assets/Block 8/Mountain.png";
import Ruins from "assets/Block 8/atlantis ruins.png";
import OceanGoddess from "assets/oceanGoddess.png";
import Roadmap from "assets/roadmap.png";
import LanternWhale from "assets/Lantern whale.png";
import LandBackgroundImage from "assets/Land background.png";
import Posidon from "assets/posidon.png";
import { Link } from "react-router-dom";
import GameVid from "assets/Whalerace.mp4";
import HU from "assets/people/HU.png";
import Bleght from "assets/people/bleght.png";
import mrm from "assets/people/mrm.png";
import shantastic from "assets/people/shantastic.png";
const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Marketplace", href: "#" },
  { name: "Company", href: "#" },
];
const teams = [
  {
    name: "The Universe",
    title: "Founder",
    description:
      "Involved in blockchain technology since 2017 and Harmony community from early 2021, HU has been building and advocating for the community non-stop since his introduction. Founded Harmony Universe, runs the Harmony Universe Validator, co-founder of OneFi Exchange, elected member of Harmony Community DAO and serves as a contributor for the Harmony Ecosystem Grants Committee.",
    image: HU,
  },
  {
    name: "Bleght",
    title: "Lead Strategist",
    description:
      "An avid gamer, blockchain enthusiast and visionary in merging the two fields together. Has been a gamer since the early days of Nintendo and holds a Computer Science degree. Started his blockchain journey in 2017 and has been a part of Harmony since early 2021. A strong advocate and believer that Harmony smartchain will be one of the top leading blockchains for GameFi in 2022.",
    image: Bleght,
  },
  {
    name: "Mr. M",
    title: "Lead Contributor",
    description:
      "Mr. M’s prior experiences include 10+ years experience as an Intelligence Analyst for the United States Air Force and Supply Chain Management Coordinator. He is currently a FINRA licensed Financial Advisor who holds his Series 7, Series 66, and Life Health and Annuity licensing. He is also one of the co-founders of OneFi Exchange.",
    image: mrm,
  },
];
const TeamCard = ({ name, title, description, image }) => {
  return (
    <>
      <div className="flex flex-col tracking-normal items-center">
        <img src={image} className="w-full pb-8" alt="" />
        <div className="text-xl text-center w-full">
          {name}, {title}
        </div>
        <div className="text-lg font-normal  mt-4 ">{description}</div>
      </div>
    </>
  );
};
export default function Home() {
  const [hidden, setHidden] = useState(true);
  const [val, setLoadedImages] = useState(0);

  return (
    <div
      onLoad={() => {
        setLoadedImages((val) => val + 1);
      }}
    >
      <div className="base-background font-sans">
        <Navbar />

        <div
          className={` max-w-screen-2xl overflow-hidden  mx-auto  relative`}
          style={{ minHeight: "800px", height: "80vh" }}
        >
          <img
            src={RightWhale}
            alt=""
            className={`${val < 6 ? "hidden" : "animated-left1"
              } hidden  lg:block absolute whale whale-3`}
          />
          <img
            src={KillerWhale}
            alt=""
            className={`${val < 6 ? "hidden" : "animated-left2"
              } hidden lg:block absolute whale whale-2`}
          />
          <img
            src={BlueWhale}
            alt=""
            className={`${val < 6 ? "hidden" : "animated-left3"
              } hidden lg:block absolute whale whale-1`}
          />
          <img
            src={WhaleShark}
            alt=""
            className={`${val < 6 ? "hidden" : "animated-right1"
              } hidden lg:block absolute whale whale-6`}
          />

          <img
            src={SpermWhale}
            alt=""
            className={`${val < 6 ? "hidden" : "animated-right2"
              } hidden lg:block absolute whale whale-5`}
          />
          <img
            src={NarWhale}
            alt=""
            className={`${val < 6 ? "hidden" : "animated-right3"
              } hidden lg:block absolute whale whale-4`}
          />

          <div className="w-full h-screen flex flex-col items-center lg:justify-center">
            <main className="mx-auto max-w-4xl px-4 lg:pb-32">
              <div data-aos="fade-up" className="text-center">
                <img
                  src={KillerWhale}
                  alt=""
                  className={`${val < 6 ? "hidden" : "animated-left2"
                    }  lg:hidden whale whale-2`}
                />


              </div>
            </main>
            <Trident to={"block2"} text={"LEAVE ORBIT"} />
            <div className="text-sm pt-8 text-white text-center">
              For the best ocean experience please view our website on a pc
            </div>
          </div>
        </div>
        <div
          id="block2"
          className={` max-w-screen-2xl overflow-hidden  mx-auto  `}
          style={{ minHeight: "100vh" }}
        >
          <div className="w-full h-full flex flex-col items-center justify-center">
            <main className="mx-auto max-w-4xl px-4 pb-12">
              <div data-aos="fade-up" className="text-center">
                <h1 className="text-4xl tracking-wider font-bold text-white sm:text-5xl md:text-7xl">
                  <span className="inline">Ready</span> <br />
                  <span className="inline">To Dive in?</span>
                </h1>
                <div className="text-xl mt-8 max-w-7xl mx-auto text-center text-black ">
                  Embark on your journey to explore the sheer vastness of the
                  Atlantys Oceans. <br />
                  Roam the enriched waters of the Seven Seas...
                  <br />
                  Battle against spectacular sea creatures...
                  <br />
                  Summon breathtaking Gods and Goddesses to help with your
                  conquests... Grow your family of sea creatures and expand your
                  territory to explore new resources.
                  <br />
                  Will you be prosperous in your adventure?
                  <br />
                </div>
                <div className="flex justify-center mt-10">
                  <Link to="/map">
                    <div className="relative h-24 w-72">
                      <img src={Button2} className="w-72 absolute" />
                      <div className="w-72 pt-14 text-white text-center font-extrabold text-xl absolute px-4">
                        {" "}
                        LAUNCH THE GAME
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="lg:flex items-center lg:w-full justify-between mt-4 mx-auto ">
                  <div className="relative hidden mx-auto w-96 h-32">
                    <img src={CloudRImage} className="w-96 absolute left-12" />
                    <div className="w-72 pt-24 text-black text-center font-extrabold text-2xl absolute pl-36">
                      {" "}
                      FIRST STEPS
                    </div>
                  </div>
                  <a
                    href="https://harmony-universe.gitbook.io/harmony-whales/"
                    target={"_blank"}
                    className="relative mx-auto w-96 h-32"
                  >
                    <img src={CloudLImage} className="w-96 absolute left-12" />
                    <div className="w-72 pt-24 text-black text-center font-extrabold text-2xl absolute pl-32">
                      {" "}
                      WHITEPAPER
                    </div>
                  </a>
                </div>
              </div>
            </main>
            <Trident to={"block3"} text={"DIVE IN"} style={"text-black"} />
          </div>
        </div>

        <div
          id={"block3"}
          className={` max-w-screen-2xl overflow-hidden  mx-auto  `}
          style={{ minHeight: "100vh" }}
        >
          <div className="w-full h-full flex flex-col items-center justify-center">
            <main className="text-left md:grid md:grid-cols-2 mx-auto max-w-7xl pt-16 items-center px-4 ">
              <img
                data-aos="fade-right"
                src={WhaleLoveImage}
                style={{ objectFit: "cover" }}
                className="flex-1"
                alt=""
              />
              <div data-aos="fade-left" className="text-center flex-1">
                <h1 className="text-5xl text-left tracking-wider font-bold ">
                  <span className="inline">IT'S A WHALE</span> <br />
                  <span className="block  xl:inline">LOVE STORY</span>
                </h1>
                <div className="text-md text-left mt-8 max-w-3xl mx-auto  ">
                  After the gods claimed the waters of Atlantys and erased the
                  majority of life from The Seven Seas. A new creature came to
                  call this world home - “The Generation Zero Whales”. Together
                  they began their new lives in the ocean depths, growing to
                  love one another. Drawing strength from this love, they
                  quickly claimed power in Atlantys. With a desire to conquer,
                  they are ready to grow their family and expand their kingdom.
                  tinuous desire to conquer, they are <br />
                  now ready to grow their family and expand their kingdom.
                </div>
                <Link to="/breeding">
                  <div className="relative h-48 w-72">
                    <img src={Button2} className="w-72 absolute" />
                    <div className="w-72 pt-14 text-white text-center font-extrabold text-2xl absolute px-4">
                      {" "}
                      LEARN MORE
                    </div>
                  </div>
                </Link>
              </div>
            </main>
            <Trident to={"block4"} text={"DIVE DEEPER"} style={"text-white"} />
          </div>
        </div>
        <div
          id="block4"
          className={` max-w-screen-2xl   mx-auto  `}
          style={{ minHeight: "100vh" }}
        >
          <div className="w-full h-full flex flex-col items-center justify-center">
            <main className="text-left text-white md:grid md:grid-cols-2 mt-16 mx-auto max-w-7xl items-center px-4 ">
              <video loop controls muted className="pr-16" autoplay="autoplay">
                <source src={GameVid} type="video/mp4" />
              </video>
              <div data-aos="fade-left" className="text-center flex-1">
                <h1 className="text-5xl text-left tracking-wider font-bold ">
                  <span className="inline">NOW THIS </span> <br />
                  <span className="block  xl:inline">IS PODRACING</span>
                </h1>
                <div className="text-md text-white text-left mt-8 max-w-3xl mx-auto  ">
                  Over the course of millennia, The Whale’s Love Story became
                  legend, causing the whales’ values to increase due to enormous
                  desire. So much so that whales now participate in deadly
                  competition; in order to earn the right to mate and reap the
                  rewards of victory...
                  <br />
                  <br />
                  Do you have what it takes to claim the first place in the
                  glorious Whale Race?
                </div>
                <a target={"_blank"} href="https://race.atlantys.one/">
                  <div className="relative h-48 w-72">
                    <img src={Button2} className="w-72 absolute" />
                    <div className="w-72 pt-14 text-white text-center font-extrabold text-2xl absolute px-4">
                      {" "}
                      LEARN MORE
                    </div>
                  </div>
                </a>
              </div>
            </main>
            <Trident to={"block5"} text={"DIVE DEEPER"} style={"text-white"} />
          </div>
        </div>
        <div
          id={"block5"}
          className={` max-w-screen-2xl   mx-auto  `}
          style={{ minHeight: "100vh" }}
        >
          <div className="w-full h-full flex flex-col items-center justify-center">
            <main className="text-left md:grid md:grid-cols-2 mx-auto max-w-7xl items-center px-4 ">
              <div className="flex-1 relative">
                <img
                  src={Fighting2}
                  alt=""
                  className="absolute fight-whale-1-animate z-0 w-64 top-24 right-24"
                />
                <img
                  src={Fighting3}
                  alt=""
                  className="absolute fight-whale-2-animate z-0 w-64 top-0 right-0"
                />
                <img src={Fighting1} className="flex-1 absolute" alt="" />
                <img
                  src={Fighting1}
                  className="flex-1"
                  style={{ visibility: "hidden" }}
                  alt=""
                />
              </div>
              <div data-aos="fade-left" className="text-center flex-1">
                <h1 className="text-5xl text-white text-left tracking-wider font-bold ">
                  <span className="inline">THE FIRST RULE OF </span> <br />
                  <span className="block  xl:inline">
                    WHALE FIGHT CLUB IS...
                  </span>
                </h1>
                <div className="text-md text-white text-left mt-8 max-w-3xl mx-auto  ">
                  With increased population, demand and scarcity, came a tidal
                  wave of social injustice. Observing the situation, the Gods of
                  Atlantys stepped in and created a codex for whales to follow…
                  The Fight Club was born and assigned to the RNG God!
                </div>
                <Link to="/battle">
                  <div className="relative h-24 w-72">
                    <img src={Button2} className="w-72 absolute" />
                    <div className="w-72 pt-14 text-white text-center font-extrabold text-2xl absolute px-4">
                      {" "}
                      LEARN MORE
                    </div>
                  </div>
                </Link>
              </div>
            </main>
            <Trident to={"block6"} text={"DIVE DEEPER"} style={"text-white"} />
          </div>
        </div>
        <div
          id={"block6"}
          className={` max-w-screen-2xl overflow-hidden  mx-auto relative `}
          style={{ minHeight: "100vh" }}
        >
          <div className="w-full h-full flex flex-col items-center justify-center">
            <img
              data-aos="fade-right"
              src={MamaTurtle}
              alt=""
              className={`hidden lg:block big-turtle-animate absolute left-0 top-0`}
            />
            <img
              src={LandBackgroundImage}
              alt=""
              className={`hidden z-0 w-full lg:block  absolute  bottom-0`}
            />
            <img
              src={Posidon}
              alt=""
              style={{ height: "900px" }}
              className={`hidden lg:block  absolute  bottom-16 -right-96`}
            />

            <img
              data-aos="fade-right"
              src={BabyTurtle}
              alt=""
              className={`hidden lg:block  absolute turtle-animate left-32 top-32`}
            />
            <img
              src={Nemo1}
              data-aos="fade-right"
              alt=""
              className={`hidden lg:block  h-16 fish-animate2 absolute top-0 left-32`}
            />
            <img
              aos
              src={Nemo2}
              data-aos="fade-right"
              alt=""
              className={`hidden lg:block  h-44 absolute fish-animate3 top-22 left-32  `}
            />

            <img
              data-aos="fade-right"
              src={Nemo3}
              alt=""
              className={`hidden lg:block  h-32 fish-animate1 absolute top-96 left-8`}
            />

            <main className="text-white mx-auto max-w-4xl px-4 mt-32">
              <div data-aos="fade-up" className="text-center">
                <h1 className="text-5xl tracking-wider font-bold text-white ">
                  <span className="inline">ATLANTYS</span> <br />
                  <span className="block  xl:inline">REAL ESTATE</span>
                </h1>
                <div className=" mt-8 max-w-2xl mx-auto text-center  ">
                  With the intensive infighting, pod-racing and breeding -
                  whales became increasingly territorial. Blatantly marking
                  their Ocean Plots and creating farms to harvest resources from
                  the depths. These plots allow them to amass treasuries and
                  arsenals, ever ready for battle. Or to rent parts of their
                  land for passive income… Are you ready to become a
                  whale-landbaron?
                </div>
              </div>
              <a target={"_blank"} href="https://land.atlantys.one">
                <div className="relative w-72 h-48 mx-auto">
                  <img src={Button2} className="w-72 absolute" />
                  <div className="w-72 pt-14 text-white text-center font-extrabold text-2xl absolute px-4">
                    {" "}
                    LEARN MORE
                  </div>
                </div>
              </a>
            </main>
            <Trident to={"block7"} text={"DIVE DEEPER"} style={"text-white"} />
          </div>
        </div>
        <div
          id="block7"
          className={` max-w-screen-2xl   mx-auto  `}
          style={{ minHeight: "100vh" }}
        >
          <div className="w-full h-full relative flex flex-col items-center justify-start">
            <div>
              <img
                src={OceanGoddess}
                style={{ width: "800px" }}
                className="hidden lg:block fight-whale-1-animate absolute top-0 -right-24"
              />
            </div>
            <main className="text-white mx-auto max-w-7xl px-4 mt-2 w-full">
              <div data-aos="fade-up" className="text-left">
                <div className="lg:flex">
                  <div>
                    <h1 className="text-5xl tracking-wider font-bold text-white ">
                      <img src={ArbiterIcon} className="w-48 mb-8" />
                      <span className="inline">ATLANTYS</span> <br />
                      <span className="inline">TOKENOMICS</span>
                    </h1>
                    <div className=" mt-8 max-w-3xl  text-left  ">
                      - Aqua is the ecosystem token for Atlantys and the Harmony
                      Universe.
                      <br />
                      - All in game transactions use Aqua.
                      <br />
                      - Purchase, rent, stake or farm with Aqua.
                      <br />
                    </div>
                    <div
                      data-aos="fade-up"
                      className="flex flex-col md:flex-row w-full lg:h-48 items-center justify-around"
                    >
                      <a
                        href="https://discord.gg/b8UWh2Hk64"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="relative h-32 w-64">
                          <img src={Button1} className="w-80 absolute" />
                          <div className="w-64 pt-12 mt-1 text-white text-center font-extrabold text-xl absolute px-4">
                            {" "}
                            DISCORD
                          </div>
                        </div>
                      </a>
                      <a
                        href="https://lootswap.finance/guilds/arb"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="relative h-32 w-64">
                          <img src={Button2} className="w-80 absolute" />
                          <div className="w-64 pt-12 mt-1 text-white text-center font-extrabold text-xl absolute px-4">
                            {" "}
                            MARKETPLACE
                          </div>
                        </div>
                      </a>
                      <Link to="/aqua">
                        <div className="relative h-32 w-64">
                          <img src={Button3} className="w-80 absolute" />
                          <div className="w-64 pt-8 mt-1 text-white text-center font-extrabold text-xl absolute px-4">
                            {" "}
                            AQUA <br /> TOKEN
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Trident to={"block8"} text={"DIVE DEEPER"} style={"text-white"} />
          </div>
        </div>
        <div
          id="block8"
          className={` max-w-screen-2xl   mx-auto  `}
          style={{ minHeight: "100vh" }}
        >
          <div className="w-full h-full flex flex-col items-center justify-start">
            <main className="relative mb-16 text-white mx-auto max-w-7xl px-4 mt-32 w-full">
              <div data-aos="fade-up" className="text-center">
                <h1 className="text-5xl tracking-wider font-bold text-white ">
                  <span className="inline">OCEAN ROAD MAP</span> <br />
                </h1>

                <img
                  src={Roadmap}
                  alt=""
                  className="w-full mt-8 "
                  style={{ width: "1800px" }}
                />
              </div>
              <img
                src={LanternWhale}
                style={{ transform: "scaleX(-1)" }}
                className="absolute -bottom-32 hidden lg:block right-0 w-80"
              />
            </main>
          </div>
        </div>
        <div
          id="block9"
          className={` max-w-screen-2xl overflow-hidden  mx-auto  `}
          style={{ minHeight: "1080px" }}
        >
          <div className="w-full h-full flex flex-col items-center justify-start">
            <img
              src={Block9Image}
              alt=""
              className="absolute w-full bottom-0"
            />

            <main className="text-white mx-auto max-w-7xl px-4 mt-8 pb-24 w-full">
              <div data-aos="fade-up" className="text-center">
                <h1 className="text-5xl tracking-wider  font-bold text-white pb-48 ">
                  <span className="inline">MEET THE DIVE TEAM</span> <br />
                  <div className="mt-12 px-8 lg:px-0 grid grid-cols-1 lg:grid-cols-3 gap-4">
                    {teams.map((e) => {
                      return <TeamCard {...e} />;
                    })}
                  </div>
                </h1>
              </div>
            </main>
          </div>
          <div className="relative w-full h-full"></div>
        </div>
      </div>
    </div>
  );
}
