import Navbar from "components/Navbar/index";
import ComingSoonWhale from "assets/comingsoon.png"

const ComingSoon = ({ }) => {
    return (<>
        <div >
            <div className="base-background h-screen" >

                <Navbar />

                <div className={` max-w-screen-2xl overflow-hidden  mx-auto  relative`} style={{ "height": "80vh" }}>
                    <div className="w-full h-full  flex flex-col items-center lg:justify-center">
                        <img src={ComingSoonWhale} className="" style={{ width: "400px" }} />
                    </div></div></div></div>


    </>);
}
export default ComingSoon