import Navbar from "components/Navbar/index";
import ComingSoonWhale from "assets/comingsoon.png"
import Button3 from "assets/buttons/button3.png"
import { useContext, useState, useEffect } from "react"
import Web3Context from "contexts/Web3Context";
const LandPage = ({ }) => {
    const [val, setVal] = useState(0)
    const { getMintedPlots, account } = useContext(Web3Context)
    useEffect(() => {
        if (account) {
            getMintedPlots().then(e => setVal(e));
        }
    }, [account])

    return (<>
        <div >
            <div className="base-background h-screen" >

                <Navbar />

                <div className={` max-w-screen-2xl overflow-hidden  mx-auto  relative`} >
                    <div className="w-full h-full mt-8  flex flex-col items-center lg:justify-center">
                        <h1 className="text-3xl tracking-wider font-extrabold text-white sm:text-3xl md:text-4xl">
                            <span className="inline">Atlantys Ocean Plots</span>{' '}<br />
                            <br />
                        </h1>

                        <h1 className="text-2xl text-black tracking-wider font-semibold mt-16 sm:text-3xl md:text-4xl">
                            <span className="inline">Currently Minted</span>{' '}<br /><br />
                            <span className="text-xl  sm:text-2xl md:text-3xl inline">{val}/3000 Ocean Plots
                            </span>{' '}<br />

                        </h1>
                        <a href="https://land.atlantys.one" target="_blank" rel="noopener noreferrer">
                            <div className='relative h-64 w-96'>
                                <img src={Button3} className='w-96 absolute' />
                                <div className="w-full pt-16 mt-4 text-white text-center font-extrabold text-2xl absolute px-4"> Mint Now!</div>

                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>


    </>);
}
export default LandPage