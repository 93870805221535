import Navbar from "components/Navbar/index";
import ComingSoonWhale from "assets/comingsoon.png"
import ArbiterIcon from "assets/aqua_icon.png";
import DefiKingdoms from "assets/Defi kingdoms logo.png";
import Lootswap from "assets/Lootswap logo.png";

const ArbPage = ({ }) => {
    return (<>
        <div >
            <div className="base-background" >

                <Navbar />

                <div className={` max-w-screen-2xl overflow-hidden  mx-auto  relative`} >
                    <div className="w-full h-full mt-8  flex flex-col items-center lg:justify-center">
                        <h1 className="text-3xl tracking-wider font-extrabold text-white sm:text-3xl md:text-4xl">
                            <span className="inline">Atlantys Tokenomics</span>{' '}<br />
                            <br />
                        </h1>
                        <h1 className="text-1xl tracking-wider font-semibold text-white sm:text-2xl md:text-3xl">
                            <span className="inline">Meet the Aqua Token</span>{' '}<br />
                        </h1>
                        <img src={ArbiterIcon} className=" mt-6 w-64" />

                        <br />
                        <br />
                        <h1 className="text-2xl tracking-wider mt-16 font-semibold text-white sm:text-3xl md:text-4xl">
                            <span className="inline">How to purchase our $AQUA Token</span>{' '}
                        </h1>
                        <h1 className="text-xl tracking-wider font-semibold text-white sm:text-2xl md:text-3xl">
                            AQUA can be traded on DefiKingdoms
                        </h1>
                        <div className="md:flex items-center mt-8 justify-around">
                            {/* <img src={Lootswap} className="h-32 mr-16" /> */}
                            <img src={DefiKingdoms} className="h-24" />
                        </div>
                        <h1 className="text-2xl text-white tracking-wider font-semibold mt-16 sm:text-3xl md:text-4xl">
                            <span className="inline">Atlantys on-game Utility Token</span>{' '}<br /><br />
                            <span className="text-xl  sm:text-2xl md:text-3xl inline">- Farmable from Ocean Plot Staking and Ocean Plot Upgrades</span>{' '}<br />
                            <span className="text-xl  sm:text-2xl md:text-3xl ">- Additional in-game rewards from racing and battles</span>

                        </h1>
                        <img src={ComingSoonWhale} className="" style={{ width: "400px" }} />
                        <span className="text-xl  sm:text-2xl md:text-3xl ">Design and Tokenomics coming soon!</span>
                        <h1 className="text-2xl tracking-wider font-semibold text-white sm:text-3xl md:text-4xl">
                            <span className="inline">Other Use Case:</span>{' '} <a href="https://harmonyuniverse.one/" target={"_blank"} className="underline text-blue-400 cursor-pointer">Harmony Universe Ecosystem</a>
                        </h1>

                    </div>
                </div>
            </div>
        </div>

    </>);
}
export default ArbPage