import Navbar from "components/Navbar/index";
import "./style.css"
const Roadmap = () => {
    return (<>
        <div className="bg-midNightBlue">
            <Navbar />

            <h1 className="text-xl tracking-tight font-extrabold py-8 text-white sm:text-2xl md:text-4xl">
                <span className="block xl:inline">Roadmap</span>{' '}
            </h1>
            <div data-aos="fade-up" class="px-2 md:px-24 py-16  container">
                <div class="timeline">
                    <div class="timeline-container primary">
                        <div class="timeline-icon">
                            1                        </div>
                        <div class="timeline-body">
                            <h4 class="timeline-title"><span class="badge">Primary</span></h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam necessitatibus numquam earum ipsa fugiat veniam suscipit, officiis repudiandae, eum recusandae neque dignissimos. Cum fugit laboriosam culpa, repellendus esse commodi deserunt.</p>
                            <p class="timeline-subtitle">1 Hours Ago</p>
                        </div>
                    </div>
                    <div class="timeline-container danger">
                        <div class="timeline-icon">
                            2                        </div>
                        <div class="timeline-body">
                            <h4 class="timeline-title"><span class="badge">Danger</span></h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam necessitatibus numquam earum ipsa fugiat veniam suscipit, officiis repudiandae, eum recusandae neque dignissimos. Cum fugit laboriosam culpa, repellendus esse commodi deserunt.</p>
                            <p class="timeline-subtitle">2 Hours Ago</p>
                        </div>
                    </div>
                    <div class="timeline-container success">
                        <div class="timeline-icon">
                            3                        </div>
                        <div class="timeline-body">
                            <h4 class="timeline-title"><span class="badge">Success</span></h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam necessitatibus numquam earum ipsa fugiat veniam suscipit, officiis repudiandae, eum recusandae neque dignissimos. Cum fugit laboriosam culpa, repellendus esse commodi deserunt.</p>
                            <p class="timeline-subtitle">6 Hours Ago</p>
                        </div>
                    </div>
                    <div class="timeline-container warning">
                        <div class="timeline-icon">
                            4                        </div>
                        <div class="timeline-body">
                            <h4 class="timeline-title"><span class="badge">Warning</span></h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam necessitatibus numquam earum ipsa fugiat veniam suscipit, officiis repudiandae, eum recusandae neque dignissimos. Cum fugit laboriosam culpa, repellendus esse commodi deserunt.</p>
                            <p class="timeline-subtitle">1 Day Ago</p>
                        </div>
                    </div>
                    <div class="timeline-container">
                        <div class="timeline-icon">
                            5                        </div>
                        <div class="timeline-body">
                            <h4 class="timeline-title"><span class="badge">Secondary</span></h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam necessitatibus numquam earum ipsa fugiat veniam suscipit, officiis repudiandae, eum recusandae neque dignissimos. Cum fugit laboriosam culpa, repellendus esse commodi deserunt.</p>
                            <p class="timeline-subtitle">3 Days Ago</p>
                        </div>
                    </div>
                    <div class="timeline-container info">
                        <div class="timeline-icon">
                            6

                        </div>
                        <div class="timeline-body">
                            <h4 class="timeline-title"><span class="badge">Info</span></h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam necessitatibus numquam earum ipsa fugiat veniam suscipit, officiis repudiandae, eum recusandae neque dignissimos. Cum fugit laboriosam culpa, repellendus esse commodi deserunt.</p>
                            <p class="timeline-subtitle">4 Days Ago</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>);
}
export default Roadmap