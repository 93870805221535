import PrimaryButton from "components/Buttons/Primary/index";
import MarketplaceModal from "components/MarketplaceModal";
import React, { useState } from "react";

import "./style.css";

const Location = ({ top, left, title, buttonIndex, link, onClick }) => {
  const width = 1536;
  const height = 945;

  const topNormalised = (top / height) * 100;
  const leftNormalised = (left / width) * 100;
  return (
    <div
      className="location-container"
      style={{
        top: `${topNormalised}%`,
        left: `${leftNormalised}%`,
      }}
    >
      {link ? (
        <a href={link} target={"_blank"}>
          <div
            className={`location-button float-${
              (buttonIndex % 2) + 1
            } button-${buttonIndex}`}
          >
            <div className="my-10 mx-20 text-white title font-bold text-3xl">
              {title}
            </div>
          </div>
        </a>
      ) : (
        <button onClick={onClick}>
          <div
            className={`location-button float-${
              (buttonIndex % 2) + 1
            } button-${buttonIndex}`}
          >
            <div className="my-10 mx-20 text-white title font-bold text-3xl">
              {title}
            </div>
          </div>
        </button>
      )}
    </div>
  );
};

export default function Map() {
  const [openMarketplace, setOpenMarketplace] = useState(false);
  const locations = [
    {
      top: 80,
      left: 100,
      title: "MARKETPLACE",
      buttonIndex: 1,
      onClick: () => {
        setOpenMarketplace(true);
      },
    },
    {
      top: 80,
      left: 1000,
      title: "TAMAGOTCHI",
      buttonIndex: 1,
      link: "https://tamagotchi.atlantys.one/",
    },
    {
      top: 155,
      left: 550,
      title: "OCEAN PLOTS",
      buttonIndex: 2,
      link: "https://land.atlantys.one/",
    },
    {
      top: 380,
      left: 600,
      title: "QUESTS",
      buttonIndex: 3,
      link: "https://breeding.atlantys.one/quest",
    },
    {
      top: 390,
      left: 1150,
      title: "BATTLES",
      buttonIndex: 2,
      link: "https://battles.atlantys.one/",
    },
    {
      top: 620,
      left: 30,
      title: "BREEDING",
      buttonIndex: 3,
      link: "https://breeding.atlantys.one/",
    },
    {
      top: 635,
      left: 525,
      title: "WHALE RACE",
      buttonIndex: 1,
      link: "https://race.atlantys.one/",
    },
  ];
  return (
    <div className="map-container">
      <MarketplaceModal open={openMarketplace} setOpen={setOpenMarketplace} />
      {locations.map((el) => {
        return <Location {...el} />;
      })}
    </div>
  );
}
