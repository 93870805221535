import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'

export default function MarketplaceModal({ open = true, setOpen }) {
    const locations = [
        {

            title: "Gen 0 Whales",
            buttonIndex: 1,
            link: "https://nftkey.app/collections/harmonywhales/",
        },
        {
            top: 80,
            left: 1000,
            title: "Gen 1 Whales",
            buttonIndex: 3,
            link: "https://nftkey.app/collections/atlantyswhalesgen1/",
        },
        {
            top: 155,
            left: 550,
            title: "OCEAN PLOTS",
            buttonIndex: 2,
            link: "https://nftkey.app/collections/atlantysoceanplots/",
        },

    ];

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-black bg-opacity-40 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                            <div>

                                <div className="mt-3 text-center sm:mt-5">
                                    {locations.map((e) => {
                                        return (<div className="">
                                            <a
                                                target={"_blank"}
                                                href={e.link}>
                                                <div
                                                    className={`location-button button-${e.buttonIndex}`}
                                                >
                                                    <div className="my-10 mx-20 text-white title font-bold text-3xl">
                                                        {e.title}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>)
                                    })}


                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6">

                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}