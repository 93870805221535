import { createContext, useEffect, useState } from "react";
import { ethers, utils, BigNumber } from 'ethers';
import landPlotAbi from "abis/plots.json";
import arbTokenAbi from "abis/arbToken.json";






const Web3Context = createContext();
//TEST NET
// const RPC_URL = "https://api.s0.b.hmny.io";
// const CHAIN_ID = 1666700000;

const RPC_URL = "https://api.harmony.one";
const CHAIN_ID = 1666600000;
const NATIVE_CURRENCY = {
    name: "one",
    symbol: "ONE", // 2-6 characters long
    decimals: 18,
}

// const MULTI_CALL_ADDRESS = "0xd078799c53396616844e2fa97f0dd2b4c145a685";
const MULTI_CALL_ADDRESS = "0x34b415f4d3b332515e66f70595ace1dcf36254c5";
const CHAIN_NAME = "Harmony Mainnet";
// const ARB_TOKEN_CONTRACT_ADDRESS = "0xbc4AC4134Dc606BAd35B2230b7f6590D5d10D894";
const ARB_TOKEN_CONTRACT_ADDRESS = "0x1A5b1109F04Cc3f45d4C533685a347656d0983E4";
const ARB_WHALE_BATTLE_CONTRACT_ADDRESS = "0x23667c4Ab8fce9EA6689D58B5C11F12256E55b42";
// const HARMONY_WHALES_CONTRACT_ADDRfESS = "0x543aC7D9ed91E05D93ea197A00aB3F41D92337CC";
const HARMONY_WHALES_CONTRACT_ADDRfESS = "0x2c6d5830dc0deb213ed8e8df666229d43a0dfc32";
// const PLOTS_CONTRACT_ADDRESS = "0xa35DE175de534C296f8E5d80cdb2a39E984379DD";
const PLOTS_CONTRACT_ADDRESS = "0xB390f78d4569aC10FEc098A9292F2B3B5839252A";
// const STAKING_CONTRACT_ADDRESS = "0x9078CEA68304D805b552F684fa9004f79c015415"
const STAKING_CONTRACT_ADDRESS = "0x3507501DdE92B1DFA0AbfD88a69DdB05B67Cd76d"
export const Web3Provider = (props) => {
    const [stakedPlots, setStakedPlots] = useState([])
    const [balance, setBalance] = useState(0);
    const [account, setAccount] = useState();
    const [signer, setSigner] = useState();
    const [contractObjects, setContractObjects] = useState();
    const [totalRewards, setTotalRewards] = useState(0);
    const [totalPlots, setTotalPlots] = useState(0);
    const [totalStakedPlots, setTotalStakedPlots] = useState(0)
    const functionsToExport = {};
    const [totalStakedRewards, setTotalStakedRewards] = useState(0)
    const [update, setUpdate] = useState(0)

    const onAccountsChanged = async (accounts) => {
        setAccount(accounts[0]);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const _signer = provider.getSigner();
        setSigner(_signer);
    }
    useEffect(() => {
        functionsToExport.connectWallet()
    })
    useEffect(() => {
        try {
            const _signer = signer || new ethers.providers.Web3Provider(
                window.ethereum,
                "any"
            );
            const arbTokenContract = new ethers.Contract(ARB_TOKEN_CONTRACT_ADDRESS, arbTokenAbi, _signer);
            const plotsContract = new ethers.Contract(PLOTS_CONTRACT_ADDRESS, landPlotAbi, _signer);
            const _contractObjects = {
                arbTokenContract,
                plotsContract,

            }
            setContractObjects(_contractObjects);
        }
        catch (e) {
        }
    }, [signer])
    const addNewChain = async () => {
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
                {
                    chainId: `0x${CHAIN_ID.toString(16)}`,
                    rpcUrls: [RPC_URL],
                    chainName: CHAIN_NAME,
                    nativeCurrency: NATIVE_CURRENCY,
                },
            ],
        });
    }
    const switchCain = async () => {
        await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${CHAIN_ID.toString(16)}` }],
        });
    }
    const promptChain = async () => {
        try {
            await switchCain();
        }
        catch (e) {
            await addNewChain();
            // await switchCain();
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const _signer = provider.getSigner();
        setSigner(_signer);

    }
    const onChainChanged = async (chainID) => {

        await promptChain()
    }

    functionsToExport.connectWallet = async () => {


        const { ethereum } = window

        if (ethereum) {
            await ethereum.request({ method: 'eth_requestAccounts' });
            const accounts = await ethereum.request({ method: 'eth_accounts' });
            await promptChain()
            ethereum.on('chainChanged', onChainChanged);
            ethereum.on('accountsChanged', onAccountsChanged);
            setAccount(accounts[0]);
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const _signer = provider.getSigner();
            setSigner(_signer);
        }
    }
    functionsToExport.getArbTokenBalance = async () => {
        try {
            console.log(account);
            const result = await contractObjects?.arbTokenContract?.balanceOf(account);
            setBalance(parseFloat(utils.formatEther(result.toString())).toFixed(3))
            return parseFloat(utils.formatEther(result.toString())).toFixed(3);
        }
        catch (e) {
            console.log(e);
        }
    }
    functionsToExport.getMintedPlots = async () => {
        try {
            console.log(account);
            const result = await contractObjects?.plotsContract?.totalSupply();
            console.log(result);
            return (result.toString());
        }
        catch (e) {
            console.log(e);
        }
    }

    return (<Web3Context.Provider value={{ account, balance, ...functionsToExport }}>
        {props.children}
    </Web3Context.Provider>)
}
export default Web3Context;