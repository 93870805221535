import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import Home from "pages/Home/index";
import Roadmap from "pages/Roadmap/index";
import AOS from "aos";
import "aos/dist/aos.css";
import Team from "pages/Team/index";
import Footer from "components/Footer/index";
import { useEffect } from "react";
import { Web3Provider } from "contexts/Web3Context";

import ComingSoon from "pages/ComingSoon/index";
import ArbPage from "pages/Arb/index";
import AudioPlayer from "components/AudioPlayer";
import LandPage from "pages/Land/index";
import Breeding from "pages/Breeding/index";
import Map from "./pages/Map/index";

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 900,
    });
  }, []);
  return (
    <>
      <Web3Provider>
        <Router>
          <div className="relative App">
            <Routes>
              <Route path="/breeding" element={<Breeding />} />
              <Route path="/podracing" element={<ComingSoon />} />
              <Route path="/battle" element={<ComingSoon />} />
              <Route path="/land" element={<LandPage />} />
              <Route path="/aqua" element={<ArbPage />} />
              <Route path="/" element={<Home />} />
              <Route path="/map" element={<Map />} />
            </Routes>
            <AudioPlayer url="assets/Atlantys_Music.mp3" />
          </div>
        </Router>
      </Web3Provider>
    </>
  );
}

export default App;
